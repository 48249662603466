.default-button {
  width: 100px;
  max-height: 25px;

}

.rm-container {
  padding-left: 100px !important;
  padding-top: 10px !important;

}

.button-container {
  padding-left: 150px !important;
  margin-top: 10px !important;
}

.containerspacing {
  padding-bottom: 100px !important;
}

.ig-resource-master-style {
  width: 100%;
}

.ig-master-shading {
  tbody tr:nth-child(odd) {
    background-color: rgba(157, 157, 157, 0.16);
  }
}

.ig-hide-id-column {
  tbody tr:nth-child(odd) {
    background-color: rgba(157, 157, 157, 0.16);
  }

  th:nth-child(1), td:nth-child(1) {
    display: none;
  }

  th:nth-child(7), td:nth-child(7) {
    display: none;
  }

}
.ig-columns {
  tbody tr:nth-child(odd) {
    background-color: rgba(157, 157, 157, 0.16);
  }

  th:nth-child(1), td:nth-child(1) {
    display: none;
  }

  #hide second column
  th:nth-child(2), td:nth-child(2) {
    display: none;
  }

  th:nth-child(2) {
    display: none;
  }
}

.resource-master-hide-3rd-column {
  th:nth-child(3), td:nth-child(3) {
    display: none;
  }
}

.ig-hide-pager {
  div.paging {
    display: none;
  }
}

.ig-detail-remove-rows {
  table tbody tr:nth-child(1) {
    display: none;
  }
}

.ig-detail-remove-2rows {
  form > ig-resource-detail-section > table > tbody > tr:nth-child(1) {
    display: none;
  }

  form > ig-resource-detail-section > table > tbody > tr:nth-child(2) {
    display: none;
  }

}

.ig-detail-remove-3rows {
  form > ig-resource-detail-section > table > tbody > tr:nth-child(1) {
    display: none;
  }

  form > ig-resource-detail-section > table > tbody > tr:nth-child(2) {
    display: none;
  }

  form > ig-resource-detail-section > table > tbody > tr:nth-child(3) {
    display: none;
  }

}
.ig-detail-remove-nested-ids {
  .frm > ig-resource-detail-section:nth-child(1) > table:nth-child(1) > tbody:nth-child(1) > tr:nth-child(4) > td:nth-child(2) > ig-resource-detail-field:nth-child(1) > ig-resource-detail-section:nth-child(1) > table:nth-child(1) > tbody:nth-child(1) > tr:nth-child(1){
    display: none;
  }

}

.ig-detail-remove-installatie-id {
  form > ig-resource-detail-section > table > tbody > tr:nth-child(9) {
    display: none;
  }

}

.ig-master-remove-installatie-id {
  #hide ninth column
  th:nth-child(9), td:nth-child(9) {
    display: none;
  }

  th:nth-child(9) {
    display: none;
  }

}

.vergunning-remove-id {
  table[path-part="permitWell"] {
    tr > th:nth-child(1) {
      display: none;
    }

    td:nth-child(1) {
      display: none;
    }
  }
}

.rotate-form {
  ig-form-field-resource-type-group {
    ig-form-field-resource-type {
      ig-form-section[data-title="DEFAULT"] {
        table {
          display: flex;
          flex-wrap: wrap;
        }

        tr:nth-child(1), tr:nth-child(2) {
          flex-wrap: wrap;
          flex: 1;
          width: 230px;
          padding: 10px;
        }

        td:nth-child(1), td:nth-child(2) {
          vertical-align: top;
          padding-left: 5px;
        }
      }
    }
  }

  ig-form-field-array[path="afwijkingen"] {
    table {
      display: inline;
      flex-wrap: wrap;
    }

    tr:nth-child(1), tr:nth-child(2) {
      flex-wrap: wrap;
      flex: 1;
      width: 230px;
      padding: 10px;
    }

    td:nth-child(1), td:nth-child(2) {
      vertical-align: top;
      padding-left: 5px;
    }
  }
}

.rotate-rd {
  ig-resource-detail-field[path-part="bereikbaar"] {

    th, td {
      display: block;
    }

    tr:nth-child(1), tr:nth-child(2) {
      flex-wrap: wrap;
      flex: 1;
      width: 230px;
      padding: 10px;
    }

    td:nth-child(1), td:nth-child(2) {
      vertical-align: top;
      padding-left: 5px;
    }
  }
}

.hide-filter-searchable-master {
  details.panel {
    display: none
  }
}